import { createRouter, createWebHistory } from "vue-router";
import Main from "../components/Pages/Main/MainPage.vue";
import StartPlay from "../components/Pages/Start/StartPlay.vue";
import AccountPage from "../components/Pages/Account/AccountPage.vue";

// import store from "../Store/Store";

const Routes = [
  {
    path: "/",
    name: "Home",
    component: null,
    children: [
      // {
      //   name: "Invenotry",
      //   path: "/invenotry",
      //   component: () =>
      //     import(
      //       "../Application/ApplicationWrapper/Pages/Inventory/Inventory.vue"
      //     ),
      // },
      {
        name: "Main",
        path: "/",
        component: Main,
        meta: {
          requiresAuth: false, // Для маршрутов, не требующих аутентификации
        },
      },
      {
        name: "StartPlay",
        path: "/start",
        component: StartPlay,
        meta: {
          requiresAuth: false, // Для маршрутов, не требующих аутентификации
        },
      },
      {
        name: "AccountPage",
        path: "/account",
        component: AccountPage,
        meta: {
          requiresAuth: true, // Для маршрутов, не требующих аутентификации
        },
      },
      { path: '/privacy-policy.pdf', redirect: window.location.href }
    ],
  },
];

const Router = createRouter({
  history: createWebHistory(),
  routes: Routes,
});

Router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth; // Определяет, требуется ли аутентификация для маршрута

  if (requiresAuth && !sessionStorage.getItem("token")) {
    // Если требуется аутентификация и токен отсутствует
    next({ path: "/" }); // Перенаправляем на главную страницу
  } else if (to.path.endsWith(".pdf")) {
    // Перенаправляем запрос на сервер для получения файла
    // window.location.href = to.fullPath;
    next(false);
  } else {
    next(); // Продолжаем нормальное выполнение навигации
  }
});

export default Router;
