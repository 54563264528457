<template>
  <div class="default-estate">
    <img src="~@/assets/icons/estate-info.svg" />
    <span class="header">{{ this.name }}</span>
    <span class="info">{{ this.text }}</span>
  </div>
</template>

<script>
import "./DefaultEstate.scss";
import { mapGetters } from "vuex";
export default {
  name: "DefaultEstate",
  props: {
    name: String,
    text: String,
  },
  components: {},
  computed: {
    ...mapGetters("Main", ["getToken"]),
  },
};
</script>
