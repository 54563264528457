import { createStore } from "vuex";
import Main from "./Modules/Main"

const Store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Main
  },
});

export default Store;
