<template>
  <div class="estate-wrapper">
    <!-- <img src="@/assets/house.png" /> -->
    <div class="img" :style="{backgroundImage: `url(${require('@/assets/house.png')}`}"></div>
    <div class="headerRow">
      <span class="text">ЛИЧНЫЙ ДОМ</span>
      <span class="text2">#{{ getState?.house?.id }}</span>
    </div>
    <div class="list">
      <div class="item">Класс: <span class="white">{{ getState?.house.name }}</span></div>
      <div class="item">Адрес: <span class="white">{{ getState?.house.streetName }}</span></div>
      <div class="item">Количество гаражных мест: <span class="white">{{ getState?.house.garage_slot }}</span></div>
    </div>
  </div>
</template>

<script>
import "./Estate.scss";
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters("Main", ["getState"]),
  },
};
</script>
