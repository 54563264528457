<template>
  <div class="account-page">
    <div class="characterInfo">
      <div class="photo" />
      <div class="col">
        <span class="description">ВАШ ПЕРСОНАЖ</span>
        <span class="white">{{ getState?.name ?? "" }}</span>
        <span class="gray">UUID: {{ getState?.uuid }}</span>
      </div>
      <div class="col">
        <span class="description">БАЛАНС НАЛИЧНЫХ</span>
        <span class="green"
          >$ {{ formatNumberWithCommas(getState?.cash) }}</span
        >
      </div>
      <div class="col">
        <span class="description">БАЛАНС В БАНКЕ</span>
        <span class="green"
          >$ {{ formatNumberWithCommas(getState?.bank) }}</span
        >
      </div>
      <div class="col">
        <span class="description">ФРАКЦИЯ</span>
        <span class="white">{{ getState?.faction?.name }}</span>
        <span class="gray" v-if="getState?.faction?.rank > 0"
          >({{ getState?.faction?.rank }} ранг)</span
        >
      </div>
      <div class="col">
        <span class="description">БАЛАНС NT-COINS</span>
        <span class="white">{{ formatNumberWithCommas(getState?.coins) }}</span>
      </div>
      <div class="btnCoins" @click="setShowUpBalance(true)">
        Пополнить счёт
        <Badge
          v-if="this.boost > 1"
          :value="'X' + this.boost"
          :style="{ backgroundColor: '#6c86e2', marginLeft: '3px' }"
        />
      </div>
    </div>
    <div class="estate">
      <div class="house">
        <HouseEstate v-if="getState?.house?.id != undefined" />
        <DefaultEstate
          v-if="getState?.house?.id == undefined"
          name="ЛИЧНЫЙ ДОМ"
          text="К сожалению вы еще не владеете домом, приобретите его в..."
        />
      </div>
      <div class="business">
        <BusinessEstate v-if="getState?.business?.id != undefined" />
        <DefaultEstate
          v-if="getState?.business?.id == undefined"
          name="БИЗНЕС"
          text="К сожалению вы еще не владеете бизнесом, приобретите его в..."
        />
      </div>
      <div class="vehicle">
        <VehiclesEstate v-if="getState?.vehicles.length != 0" />
        <DefaultEstate
          v-if="getState?.vehicles.length == 0"
          name="АВТОМОБИЛЬ"
          text="К сожалению вы еще не владеете авто, приобретите его в..."
        />
      </div>
    </div>
    <UpBalance
      v-show="this.showUpBalance"
      :login="this.getState.email"
      @setShowUpBalance="setShowUpBalance"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import "./Account.scss";
import Badge from "primevue/badge";
import UpBalance from "./Components/Balance/UpBalance.vue";
import DefaultEstate from "./Components/DefaultEstate/DefaultEstate.vue";
import VehiclesEstate from "./Components/Estates/VehiclesEstate.vue";
import HouseEstate from "./Components/Estates/HouseEstate.vue";
import BusinessEstate from "./Components/Estates/BusinessEstate.vue";
export default {
  name: "AccountPage",
  components: {
    DefaultEstate,
    VehiclesEstate,
    HouseEstate,
    BusinessEstate,
    UpBalance,
    Badge,
  },
  data() {
    return {
      showUpBalance: false,
      boost: 1,
    };
  },
  computed: {
    ...mapGetters("Main", ["getToken", "getState"]),
  },
  methods: {
    setShowUpBalance(state) {
      this.showUpBalance = state;
    },
    formatNumberWithCommas(number) {
      if (typeof number !== "number") {
        return number; // Вернуть значение без изменений, если это не число
      }

      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    isUpdateNeeded() {
      if (sessionStorage.getItem("lastupdate") == null) return true;
      const currentTime = new Date();
      const lastUpdateTime = new Date(sessionStorage.getItem("lastupdate")); // Преобразование строки в объект Date
      const timeDifference = currentTime.getTime() - lastUpdateTime.getTime();
      const minutesDifference = Math.floor(timeDifference / (1000 * 60)); // Разница в минутах

      return minutesDifference >= 5;
    },
  },
  mounted() {
    // console.log(this.isUpdateNeeded());
    // console.log(sessionStorage.getItem("lastupdate"));
    if (this.isUpdateNeeded() || this.getState.name == "") {
      sessionStorage.setItem("lastupdate", new Date().toISOString());
      const app = this;
      axios
        .get("/api/Userdata/GetData", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then(function (response) {
          // console.log(response);
          const data = response.data;
          for (let key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              app.$store.commit("Main/setParam", {
                id: key,
                value: data[key],
              });
            }
          }
        })
        .catch(function (error) {
          console.error(error);
        });
      axios
        .get("/api/Payment/Boost")
        .then(function (response) {
          // console.log(response);
          const boostVal = response.data;
          app.boost = boostVal;
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  },
};
</script>
