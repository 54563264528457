<template>
    <div class="ntTag-wrapper" :class="severity" v-html="this.text"></div>
  </template>
  
  <script>
  import "./ntTag.scss";
  export default {
    props: {
      text: String,
      severity: String,
    },
    computed: {},
    data() {
      return {};
    },
    methods: {},
  };
  </script>
  