<template>
  <div class="headerDiv">
    <div class="row">
      <div class="logo">
        <img src="../../assets/logo.png" alt="" />
        <div class="col">
          <span>NEWTOWN</span>
          <span>ROLEPLAY</span>
        </div>
      </div>
      <ntTag
        :text="
          `<font color='#FFFFFF'>Текущий онлайн:</font>&nbsp` +
          (this.loading ? 'Загрузка...' : formatNumberWithCommas(this.online))
        "
        severity="success"
      />
    </div>

    <div class="links">
      <a href="https://forum.newtownrp.online/">Наш форум</a>
      <a href="https://vk.com/newtowngta5">Группа Вконтакте</a>
      <a href="https://discord.gg/newtowngta5">Discord</a>
    </div>

    <div class="contacts">
      <span>support@newtownrp.online</span>
      <!-- <a href="https://discord.gg/newtowngta5"
        ><img src="@/assets/icons/discord.svg" alt="Discord"
      /></a> -->
    </div>

    <div class="btns">
      <ntButton
        severity="success"
        :text="this.$route.name == 'Main' ? 'Личный кабинет' : 'На главную'"
        @click="this.$emit('setShowAuth', true)"
      />
      <!-- <BlueBtn
        :text="this.$route.name == 'Main' ? 'Личный кабинет' : 'На главную'"
        @click="this.$emit('setShowAuth', true)"
      /> -->
      <!-- <a href="https://forum.newtownrp.online/" class="otherBtn">Форум</a> -->
    </div>

    <!-- <BlueBtn
      :text="'Форум'"
      @click="this.$emit('setShowAuth', true)"
    /> -->
  </div>
  <!-- <div class="online">
    <img src="../../assets/plane.png" alt="" />
    <span class="text">ОНЛАЙН</span>
    <span class="number">
      {{ this.loading ? "Загрузка..." : formatNumberWithCommas(this.online) }}
    </span>
  </div> -->
</template>

<script>
import axios from "axios";
import "./Header.scss";
// import BlueBtn from "../BlueBtn/BlueBtn.vue";
export default {
  name: "HeaderTop",
  components: {
    // BlueBtn,
  },
  data() {
    return {
      loading: false,
      online: 0,
      showAuth: false,
    };
  },
  mounted() {
    this.loadOnline();
  },
  methods: {
    loadOnline() {
      this.loadOnline = true;
      axios
        .get("https://cdn.rage.mp/master/")
        .then((response) => {
          if ("s1.newtownrp.online:22005" in response.data) {
            this.online = response.data["s1.newtownrp.online:22005"]?.players;
          } else {
            this.online = 9999;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatNumberWithCommas(number) {
      if (typeof number !== "number") {
        return number; // Вернуть значение без изменений, если это не число
      }

      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
