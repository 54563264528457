<template>
  <div class="main" v-cloak>
    <ntButton severity='success' :text="'НАЧАТЬ ИГРАТЬ'" @click="()=> this.$router.push('/start')" />
    <!-- <BlueBtn text="НАЧАТЬ ИГРАТЬ" :style="{ fontWeight: '800' }" @click="()=> this.$router.push('/start')" /> -->
  </div>
</template>

<script>
// import BlueBtn from "@/components/BlueBtn/BlueBtn.vue";
import "./Main.scss";
export default {
  name: "MainPage",
  components: {
    // BlueBtn,
  },
};
</script>
