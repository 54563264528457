export default {
  namespaced: true,
  state: {
    token: "",
    email: "",
    name: "",
    uuid: 0,
    cash: 0,
    bank: 0,
    faction: {
      // name: "",
      // rank: "character.group",
    },
    vehicles: [],
    coins: 0,
    business: {},
    house: {},
  },
  getters: {
    getToken: () => sessionStorage.getItem("token"),
    getState: (state) => state,
    //   getBusiness: (state) => state,
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      sessionStorage.setItem("token", payload);
    },
    setParam(state, payload) {
      state[payload.id] = payload.value;
    },
  },
  actions: {
    setToken({ commit }, payload) {
      commit("setToken", payload);
    },
    setParam({ commit }, payload) {
      commit("setParam", payload);
    },
  },
};
