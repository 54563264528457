<template>
  <div
    class="wrapper"
    :style="{ backgroundImage: `url('${selectedBackground}')` }"
  >
    <Toast />
    <Header @setShowAuth="setShowAuth" />
    <AuthWindow v-show="this.showAuth" @setShowAuth="setShowAuth" />
    <div class="view" v-cloak>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
    <Bottom />
  </div>
</template>

<script>
import "./App.scss";
import axios from "axios";
import Toast from "primevue/toast";
import { mapGetters } from "vuex";
import Header from "./components/Header/Header.vue";
import AuthWindow from "./components/AuthWindow/AuthWindow.vue";
import Bottom from "./components/Bottom/Bottom.vue";

import background1 from "@/assets/background.png";
import background2 from "@/assets/background-2.png";

export default {
  name: "App",
  components: {
    Toast,
    Header,
    AuthWindow,
    Bottom,
  },
  data() {
    return {
      selectedBackground: "",
      showAuth: false,
    };
  },
  computed: {
    ...mapGetters("Main", ["getToken"]),
  },
  methods: {
    setShowAuth(state) {
      if (state == false) {
        this.showAuth = state;
        return;
      }
      const token = sessionStorage.getItem("token");
      const app = this;
      if (this.$route.name == "Main") {
        if (token != null) {
          axios
            .get("/api/Login/Validate", {
              params: {
                token: token,
              },
            })
            .then(function () {
              app.$router.push("/account");
            })
            .catch(function () {
              app.showAuth = state;
              sessionStorage.removeItem("token");
            });
        } else {
          this.showAuth = state;
        }
      } else {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    const routeName = this.$route.name;
    if (routeName === "Main") {
      // устанавливаем backgroundImage для страницы "Main"
      this.selectedBackground = background1;
    } else {
      // устанавливаем другой backgroundImage по умолчанию
      this.selectedBackground = background2;
    }
    if (this.getToken == null) return;
    axios
      .get("/api/Login/Validate", {
        params: {
          token: this.getToken,
        },
      }) // eslint-disable-next-line
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.error(error);
        sessionStorage.removeItem("token");
      });
  },
  watch: {
    "$route.name"(newRouteName) {
      if (newRouteName === "Main") {
        // устанавливаем backgroundImage для страницы "Main"
        this.selectedBackground = background1;
      } else {
        // устанавливаем другой backgroundImage по умолчанию
        this.selectedBackground = background2;
      }
    },
  },
};
</script>
