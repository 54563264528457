<template>
  <div class="UpBalance">
    <div class="window">
      <div class="closeBtn" @click="this.$emit('setShowUpBalance', false)">
        <img src="@/assets/icons/close.svg" alt="" />
      </div>
      <span class="headerText">
        ПОПОЛНЕНИЕ<br />
        ИГРОВОГО СЧЕТА
      </span>
      <div class="warning">
        <span>
          Уважаемые игроки! <br />
          По техническим причинам пополнение счёта производится через
          администраторов сервера:<br />
          напишите нам в репорт - "меню F4" > "Жалобы"<br />
          или в Discord - чат "? помощь ?"
        </span>
      </div>
      <!-- <div class="input">
        <div class="icon">
          <img src="@/assets/icons/user.svg" alt="" />
        </div>
        <div class="col">
          <div class="text">E-MAIL</div>
          <input
            type="text"
            placeholder="Ваш логин..."
            spellcheck="false"
            :value="this.login"
            disabled
          />
        </div>
      </div> -->
      <!-- <div class="input">
        <div class="icon">
          <img src="@/assets/icons/password.svg" alt="" />
        </div>
        <div class="col">
          <div class="text">СУММА</div>
          <InputNumber
            v-model="value"
            :min="1"
            :max="1000000"
            placeholder="Введите сумму..."
            @input="changeNtCoins"
          />
        </div>
      </div> -->
      <!-- <SelectButton
        class="selectPayments"
        v-model="typePaymentValue"
        :options="typePayments"
        option-label="name"
      /> -->
      <span class="text" v-show="value != null">{{
        "Вы получите " + discountedValue + " NTCoins"
      }}</span>
      <!-- <ntButton
        severity="success"
        text="Пополнить счёт"
        @click="redirectToPayment"
        :disabled="this.typePaymentValue == null"
      /> -->

      <div class="discounts">
        <div class="item" v-for="(it, index) in discounts" :key="index">
          <div class="text">{{ it.text }}</div>
          <div class="desc">{{ it.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import "./UpBalance.scss";
// import BlueBtn from "@/components/BlueBtn/BlueBtn.vue";
// import InputNumber from "primevue/inputnumber";
export default {
  name: "UpBalance",
  components: {
    // BlueBtn,
    // InputNumber,
  },
  data() {
    return {
      value: null,
      boost: 1,
      discounts: [
        {
          text: "+5%",
          desc: "ОТ 1.000 Р.",
          threshold: 1000,
        },
        {
          text: "+10%",
          desc: "ОТ 2.000 Р.",
          threshold: 2000,
        },
        {
          text: "+15%",
          desc: "ОТ 5.000 Р.",
          threshold: 5000,
        },
        {
          text: "+20%",
          desc: "ОТ 10.000 Р.",
          threshold: 10000,
        },
        {
          text: "+30%",
          desc: "ОТ 25.000 Р.",
          threshold: 25000,
        },
        {
          text: "+40%",
          desc: "ОТ 50.000 Р.",
          threshold: 50000,
        },
      ],
      typePaymentValue: null,
      typePayments: [
        {
          name: "Карты РФ",
          value: 1,
        },
        {
          name: "Международные карты",
          value: 2,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("Main", ["getToken", "getState"]),
    discountedValue() {
      if (this.value === null) return null;

      let discountedValue = this.value;

      // Применяем правила скидок
      this.discounts.forEach((discount) => {
        const threshold = discount.threshold;
        if (this.value >= threshold) {
          const percentage = parseInt(
            discount.text.replace("+", "").replace("%", "")
          );
          discountedValue = this.value + this.value * (percentage / 100);
        }
      });

      return Math.round(discountedValue * this.boost);
    },
  },
  props: {
    login: String,
  },
  mounted() {
    const app = this;
    axios
      .get("/api/Payment/Boost")
      .then(function (response) {
        // console.log(response);
        const boostVal = response.data;
        app.boost = boostVal;
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  methods: {
    clickAuth() {},
    changeNtCoins(val) {
      this.value = val.value;
      if (this.value > 1000000) {
        this.value = 1000000;
      }
    },
    redirectToPayment() {
      if (this.login == null || this.login == "" || this.login.length < 5) {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: "Некорректно указан e-mail",
          life: 3000,
        });
        return;
      }
      if (this.typePaymentValue == null) {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: "Некорректно указан тип олпаты",
          life: 3000,
        });
        return;
      }
      if (this.value <= 0 || this.value > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: "Некорректно указан сумма",
          life: 3000,
        });
        return;
      }
      const app = this;
      const amount = this.value;
      const characterId = this.getState.uuid;
      const currency = "RUB";
      const amountWithBoost = this.discountedValue;
      const typePayment = this.typePaymentValue.value;
      axios
        .get("/api/Payment/Generate", {
          params: {
            typePayment: typePayment,
            characterId: characterId,
            orderAmount: amount,
            amountWithBoost: amountWithBoost,
            currency: currency,
          },
        })
        .then(function (response) {
          const data = response.data;
          if (data.error) {
            app.$toast.add({
              severity: "error",
              summary: "Ошибка",
              detail: data.error?.message,
              life: 3000,
            });
            return;
          }

          const url = response.data;
          window.location.href = url;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>
