<template>
  <div class="estate-wrapper">
    <!-- <img src="@/assets/business.png" /> -->
    <div class="img" :style="{backgroundImage: `url(${require('@/assets/business.png')}`}"></div>
    <div class="headerRow">
      <span class="text">Бизнес</span>
      <span class="text2">#{{ getState?.business?.id }}</span>
    </div>
    <div class="list">
      <div class="item">
        Название: <span class="white">{{ getState?.business.name }}</span>
      </div>
      <div class="item">
        Сейф: <span class="white">{{ "$ " + formatNumberWithCommas(getState?.business.safe) }}</span>
      </div>
      <div class="item">
        Банк: <span class="white">{{ "$ " + formatNumberWithCommas(getState?.business.bank) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import "./Estate.scss";
import { mapGetters } from "vuex";
export default {
  name: "DefaultEstate",
  components: {},
  computed: {
    ...mapGetters("Main", ["getState"]),
  },
  methods: {
    formatNumberWithCommas(number) {
      if (typeof number !== "number") {
        return number; // Вернуть значение без изменений, если это не число
      }

      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  }
};
</script>
