<template>
  <div class="ntContainer-wrapper">
    <slot></slot>
  </div>
</template>

<script>
import "./ntContainer.scss";
export default {
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
