<template>
  <BlockUI class="ntButton-block" :blocked="this.disabled">
    <div class="ntButton-wrapper" :style="style" :class="severity" v-html="this.text" :disabled="this.disabled !== null && this.disabled"></div>
  </BlockUI>
  
</template>

<script>
import "./ntButton.scss";
export default {
  props: {
    text: String,
    severity: String,
    disabled: Boolean
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
