<template>
  <div class="auth">
    <div class="window">
      <div class="closeBtn" @click="this.$emit('setShowAuth', false)">
        <img src="@/assets/icons/close.svg" alt=""/>
        <!-- <simple-svg :src="require('../../assets/icons/close.svg')" /> -->
      </div>
      <span class="headerText">
        АВТОРИЗАЦИЯ В <br />
        ЛИЧНЫЙ КАБИНЕТ
      </span>
      <div class="input">
        <div class="icon">
        <img src="@/assets/icons/user.svg" alt=""/>
          <!-- <simple-svg :src="require('../../assets/icons/user.svg')" /> -->
        </div>
        <div class="col">
          <div class="text">ВВЕДИТЕ ЛОГИН</div>
          <input
            type="text"
            placeholder="Ваш логин..."
            spellcheck="false"
            v-model="login"
          />
        </div>
      </div>
      <div class="input">
        <div class="icon">
        <img src="@/assets/icons/password.svg" alt=""/>
          <!-- <simple-svg :src="require('../../assets/icons/password.svg')" /> -->
        </div>
        <div class="col">
          <div class="text">ВВЕДИТЕ ПАРОЛЬ</div>
          <input
            type="password"
            placeholder="Ваш пароль..."
            spellcheck="false"
            v-model="password"
            maxlength="35"
            minlength="4"
          />
        </div>
      </div>
      <ntButton
        severity="success"
        :text="'Войти в личный кабинет'"
        @click="clickAuth"
        :style="{ marginTop: '9.259vmin' }"
      />
      <!-- <BlueBtn
        text="Войти в личный кабинет"
        :style="{ width: '100%', marginTop: '9.259vmin' }"
        @click="clickAuth"
      /> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "./Auth.scss";
// import BlueBtn from "../BlueBtn/BlueBtn.vue";
export default {
  name: "AuthWindow",
  components: {
    // BlueBtn,
  },
  data() {
    return {
      login: "",
      password: "",
    };
  },
  mounted() {},
  methods: {
    clickAuth() {
      axios
        .post(
          "/api/Login",
          {
            login: this.login,
            password: this.password,
          },
          {
            baseURL: "",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("Main/setToken", response.data);
          this.$toast.add({
            severity: "success",
            summary: "Успешно",
            detail: "Вы авторизовались",
            life: 3000,
          });
          this.$emit("setShowAuth", false);
          try {
            this.$router.push("/account");
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.log(error)
          this.$toast.add({
            severity: "error",
            summary: "Ошибка",
            detail: "Неправильный логин или пароль",
            life: 3000,
          });
        });
    },
  },
};
</script>
