import { createApp } from "vue";
import App from "./App.vue";
import Router from "./Router/Router";
import VueSimpleSVG from "vue3-simple-svg";
import Store from "./Store/Store";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ntButton from "./components/ntButton/ntButton.vue";
import ntTag from "./components/ntTag/ntTag.vue";
import ntContainer from "./components/ntContainer/ntContainer.vue";
//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";

//core
import "primevue/resources/primevue.min.css";

import SelectButton from "primevue/selectbutton";
import BlockUI from "primevue/blockui";

createApp(App)
  .use(Router)
  .use(VueSimpleSVG)
  .use(Store)
  .use(PrimeVue)
  .use(ToastService)
  .component("ntButton", ntButton)
  .component("ntTag", ntTag)
  .component("ntContainer", ntContainer)
  .component('SelectButton', SelectButton)
  .component('BlockUI', BlockUI)
  .mount("#app");
