<template>
  <div class="startPlay">
    <span class="header">Как начать играть?</span>
    <span class="description"
      >Начни играть на сервере<br />пройдя всего 3 простых шага!</span
    >
    <div class="blocks">
      <div class="block">
        <img src="@/assets/gta5.png" alt="" />
        <span class="header">Шаг 1</span>
        <span class="description"
          >Купи и установи лицензионную GTA5. Найти можно по ссылкам, указанным ниже. Если игра куплена, пропускай
          этот шаг.</span
        >
        <div class="row">
          <a
            class="btn_45"
            href="https://store.epicgames.com/ru/p/grand-theft-auto-v?epic_affiliate=allkeyshopcom&epic_gameId=0584d2013f0149a791e7b9bad0eec102"
            >Epic Games</a
          >
          <a
            class="btn_45 yellow"
            href="https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/"
            >Steam</a
          >
        </div>
        <div class="row">
          <a
            class="btn_100"
            href="https://store.rockstargames.com/ru/game/buy-gta-v"
            >Social Club</a
          >
        </div>
      </div>
      <div class="block">
        <img src="@/assets/ragemp.png" alt="" />
        <span class="header">Шаг 2</span>
        <span class="description">Скачай и установи лаунчер RageMP. Ссылка указана ниже.</span>
        <div class="row">
          <a class="btn" href="https://rage.mp/">Скачать</a>
        </div>
      </div>
      <div class="block">
        <img src="@/assets/newtown_start.png" alt="" />
        <span class="header">Шаг 3</span>
        <span class="description">Скопируй IP-адрес нажав на кнопку. Введи IP-адрес в лаунчере Rage MP или найди сервер по названию NewTown RP.</span>
        <div class="row">
          <div
            class="btn"
            @click="copyToClipboard('s1.newtownrp.online:22005')"
          >
            Скопировать
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./StartPlay.scss";
export default {
  name: "StartPlay",
  components: {},
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Успешно",
            detail: "IP адрес скопирован в буфер обмена",
            life: 1000,
          });
        })
        .catch(() => {});
    },
  },
};
</script>
