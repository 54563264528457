<template>
  <div class="estate-wrapper">
    <div class="img" :style="{backgroundImage: `url(${require('@/assets/vehicles.png')}`}"></div>
    <!-- <img src="@/assets/vehicles.png" /> -->
    <div class="headerRow">
      <span class="text">ТРАНСПОРТ</span>
      <span class="text2">X{{ getState?.vehicles?.length }}</span>
    </div>
    <div class="list">
      <div class="item" :key="index" v-for="(it, index) in getState.vehicles">
        {{ index + 1 + ". " + it }}
      </div>
    </div>
  </div>
</template>

<script>
import "./Estate.scss";
import { mapGetters } from "vuex";
export default {
  name: "DefaultEstate",
  components: {},
  computed: {
    ...mapGetters("Main", ["getState"]),
  },
};
</script>
